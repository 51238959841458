import Vue from 'vue'
import VueRouter from 'vue-router'
import Nprogress from 'nprogress'
import request from '@/api/index'
import store from '@/store/index'
import { Message } from 'element-ui'
import { WalletEthers } from '@/utils/walletconnect2'
import { getAccount, watchAccount, disconnect } from '@wagmi/core'
import walletSign from '@/components/walletSign/index.js'

Vue.use(VueRouter)

let unWatch = null

const bindHandler = async () => {
  try {
    console.log('有wacth吗---', unWatch)
    const { isConnected } = getAccount()
    if (!isConnected) {
      await WalletEthers.modal.openModal()
      if (!unWatch) {
        unWatch = watchAccount(async (account) => {
          if (account.address && account.isConnected) walletSign.install({})
        })
      }
    } else walletSign.install({})
  } catch (err) {
    console.log('err', err)
    this.$message({
      message:
        err?.reason || err?.data?.message || err?.message || err?.msb || err,
      type: 'error',
      showClose: true,
      customClass: 'myMessage',
    })
    if (unWatch) {
      unWatch()
      unWatch = null
    }
    await disconnect()
  }
}

const routes = [
  {
    path: '/',
    redirect: '/Home',
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('@/views/Home/index/index.vue'),
    meta: { refresh: false },
  },
  {
    path: '/Discover',
    name: 'Discover',
    component: () => import('@/views/Discover/index/index.vue'),
    meta: { refresh: false },
  },
  {
    path: '/NFTs',
    name: 'NFTs',
    component: () => import('@/views/NFTs/index/index.vue'),
    meta: { refresh: false },
  },
  {
    path: '/NFTDetail',
    name: 'NFTDetail',
    component: () => import('@/views/NFTs/detail/index.vue'),
    meta: { refresh: false },
  },
  {
    path: '/videoDetail',
    name: 'VideoDetail',
    component: () => import('@/views/Discover/detail/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/Marketplace',
    name: 'Marketplace',
    component: () => import('@/views/Marketplace/index/index.vue'),
    meta: { refresh: true },
  },
  // {
  //   path: '/About',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import('@/views/About/index.vue'),
  //   meta:{refresh:false}
  // },
  {
    path: '/Transfer',
    name: 'Transfer',
    component: () => import('@/views/NFTs/trasfer/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/SpectaclesDetail',
    name: 'SpectaclesDetail',
    component: () => import('@/views/NFTs/detail/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/Wallet',
    name: 'Wallet',
    component: () => import('@/views/User/wallet/index.vue'),
    meta: { refresh: true },
  },
  // {
  //   path: '/campaignCenter',
  //   name: 'Campaign',
  //   component: () => import('@/views/campaignCenter/index.vue'),
  //   meta: { refresh: true },
  // },
  {
    path: '/CoinTransfer',
    name: 'CoinTransfer',
    component: () => import('@/views/User/transfer/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/Chain',
    name: 'Chain',
    component: () => import('@/views/User/chain/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: () => import('@/views/User/profile/index/index.vue'),
  },
  {
    path: '/SelfProfile',
    name: 'SelfProfile',
    component: () => import('@/views/User/selfprofile/index.vue'),
    meta: { refresh: true },
    children: [
      {
        path: 'My',
        // name:'My',
        component: () => import('@/views/User/selfprofile/my/index.vue'),
        meta: { refresh: true },
      },
      {
        path: 'Creation',
        // name:'Creation',
        component: () => import('@/views/User/selfprofile/creation/index.vue'),
        meta: { refresh: true },
      },
      // {
      //   path:'',
      //   redirect:'My',
      // },
    ],
  },
  {
    path: '/ProfileEdit',
    name: 'ProfileEdit',
    component: () => import('@/views/User/profile/edit/index.vue'),
  },
  // {
  //   path: '/Referral',
  //   name: 'Referral',
  //   component: () => import('@/views/User/referral/index.vue'),
  //   meta: { refresh: true },
  // },
  {
    path: '/Analyze',
    name: 'Analyze',
    component: () => import('@/views/User/analyze/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/Task',
    name: 'Task',
    component: () => import('@/views/Task/index/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/TaskRecord',
    name: 'TaskRecord',
    component: () => import('@/views/Task/record/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/Creators',
    name: 'Creators',
    component: () => import('@/views/Creators/index/index.vue'),
  },
  {
    path: '/PromoteUsers',
    name: 'PromoteUsers',
    component: () => import('@/views/User/promote/users/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/PromoteRewards',
    name: 'PromoteRewards',
    component: () => import('@/views/User/promote/reward/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/Incentive',
    name: 'Incentive',
    component: () => import('@/views/User/incentive/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/Collect',
    name: 'Collect',
    component: () => import('@/views/User/collect/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/Youtube',
    name: 'Youtube',
    component: () => import('@/views/User/youtube/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/WalletLog',
    name: 'WalletLog',
    component: () => import('@/views/User/WalletLog/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/Attention',
    name: 'Attention',
    component: () => import('@/views/User/attention/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/Fans',
    name: 'Fans',
    component: () => import('@/views/User/fans/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/nftTransfer',
    name: 'nftTransfer',
    component: () => import('@/views/User/nftTransfer/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/pointHistory',
    name: 'pointHistory',
    component: () => import('@/views/User/pointHistory/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/Policy',
    name: 'Policy',
    component: () => import('@/views/User/policy/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/Beta',
    name: 'Beta',
    component: () => import('@/views/Beta/index.vue'),
  },
  {
    path: '/Leaderboard',
    name: 'Leaderboard',
    component: () => import('@/views/Leaderboard/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/ExchangeHistory',
    name: 'ExchangeHistory',
    component: () => import('@/views/User/exchangeHistory/index.vue'),
    meta: { refresh: true },
  },
  {
    path: '/UploadVideo',
    name: 'UploadVideo',
    component: () => import('@/views/User/uploadVideo/index.vue'),
    meta: { refresh: true },
  },
  // {
  //   path: '/Started',
  //   name: 'Started',
  //   component: () => import('@/views/User/Started/index.vue'),
  // },
  {
    path: '/Started',
    name: 'Started',
    component: () => import('@/views/User/Started/index2.vue'),
  },
  {
    path: '/Referral',
    name: 'Referral',
    component: () => import('@/views/User/Invitation/index.vue'),
  },
  {
    path: '/Success',
    name: 'Success',
    component: () => import('@/views/User/Success/index.vue'),
  },
  {
    path: '/activitycenter',
    name: 'Campaign',
    component: () => import('@/views/activitycenter/index.vue'),
  },
  {
    path: '/activityinfo',
    name: 'activityinfo',
    component: () => import('@/views/activitycenter/info.vue'),
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404'),
  },
  {
    path: '*',
    redirect: '/404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const addressRouters = [
  'Transfer',
  'Wallet',
  'CoinTransfer',
  'Chain',
  'nftTransfer',
]

router.beforeEach(async (to, from, next) => {
  console.log(to, from)
  window.scrollTo({ top: 0 })
  console.log('这是---', store.getters.userInfo)
  if (
    store.getters.userInfo &&
    !store.getters.userInfo.chain_address &&
    addressRouters.includes(to.name)
  ) {
    console.log(
      '路由判定---',
      store.getters.userInfo,
      !store.getters.userInfo.chain_address,
      addressRouters.includes(to.name)
    )
    Message({
      message: 'please bind wallet!',
      type: 'error',
      showClose: true,
      customClass: 'myMessage',
    })
    bindHandler()
    return Nprogress.done()
  }
  // Nprogress.inc()
  if (to.path == from.path && to.path == '/videoDetail') {
    location.reload()
  }
  next()
  if (store.state.user.token) {
    let res = await request({
      path: 'user/getWalletDetail',
      data: { currency_key: 'taice' },
    })
    store.commit('user/SET_SPK', res.data?.value || 0)
  }
})

router.afterEach((to, from) => {
  // setTimeout(() => {
  //   Nprogress.done()
  // }, 20000);
})

export default router
