var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{ref:"header",staticClass:"flex flex-dir flex-ac flex-jsb",style:({ width: `${_vm.wdWidth}px`, height: _vm.shrinkHeight }),on:{"click":_vm.changeHeight}},[_c('div',{staticClass:"flex flex-jc flex-ac"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.wdWidth <= 900),expression:"wdWidth <= 900"}],staticClass:"hamburg",attrs:{"src":require("@/assets/Hamburg.svg")},on:{"click":function($event){$event.stopPropagation();_vm.showFlag = !_vm.showFlag}}}),_c('div',{staticClass:"logo_wrapper flex flex-jc flex-ac"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo.png")},on:{"click":_vm.backHandler}}),_c('div',{staticClass:"beta flex flex-jc flex-ac",on:{"click":function($event){$event.stopPropagation();return _vm.jumpBeta.apply(null, arguments)}}},[_vm._v("Beta")])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.wdWidth > 900),expression:"wdWidth > 900"}],staticClass:"tag flex flex-jc flex-ac"},_vm._l((_vm.tagList.slice(0, 7)),function(tag,tIndex){return _c('li',{key:tIndex,staticClass:"tag_item",style:({
          color: _vm.activeIndex == tIndex ? '#FE5900' : 'var(--gray)',
          fontFamily:
            _vm.activeIndex == tIndex ? 'SVN-GilroyBold' : 'SVN-GilroyMedium',
          fontSize: _vm.wdWidth > 2560 ? '1rem' : '1rem',
        }),on:{"click":function($event){$event.stopPropagation();return _vm.changeTag(tIndex)}}},[_vm._v(" "+_vm._s(tag)+" ")])}),0)]),_c('div',{staticClass:"flex flex-jc flex-jfe flex-ac",staticStyle:{"flex":"1"}},[_c('div',{staticClass:"top_search flex flex-ac",staticStyle:{"flex":"1"}},[_c('img',{staticClass:"top_search_icon",attrs:{"src":require("@/assets/search.svg")}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],ref:"search",staticClass:"top_search_input",attrs:{"type":"text","placeholder":"Search..."},domProps:{"value":(_vm.keyword)},on:{"input":function($event){if($event.target.composing)return;_vm.keyword=$event.target.value}}}),_c('el-tooltip',{attrs:{"content":"Search"}},[_c('div',{staticClass:"top_search_btn flex flex-jc flex-ac",on:{"click":function($event){return _vm.toList()}}})])],1),(_vm.token)?_c('div',{staticClass:"uploadicon_search",on:{"click":_vm.tosearch}},[_c('img',{attrs:{"src":require("@/assets/search.png"),"alt":""}})]):_vm._e(),(_vm.token)?_c('div',{staticClass:"uploadicon",on:{"click":_vm.touploadvideo}},[_c('img',{attrs:{"src":require("@/assets/uploding.png"),"alt":""}})]):_vm._e(),(_vm.token)?_c('div',{staticClass:"right flex flex-jc flex-ac"},[(_vm.wdWidth <= 900)?[_c('img',{staticClass:"btn_avatar",attrs:{"src":_vm.avatar || require('@/assets/default-avatar.png')},on:{"click":function($event){$event.stopPropagation();return _vm.btnHandler.apply(null, arguments)},"mouseenter":_vm.btnHandler,"mouseleave":_vm.btnHandler}}),_c('div',{staticClass:"flex flex-jc flex-ac",on:{"click":function($event){$event.stopPropagation();return _vm.btnHandler.apply(null, arguments)}}},[_c('img',{staticClass:"right_info_arrow",style:({ transform: `rotate(${_vm.showPop ? 0 : -90}deg)` }),attrs:{"src":require("@/assets/down.svg")}}),_c('div',{staticClass:"btn_pop flex flex-col flex-jfs flex-ac",style:({
              height: `${_vm.showPop ? 20 : 0}rem`,
              border: `${
                _vm.showPop ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'
              }`,
              pointEvents: _vm.showPop ? 'auto' : 'none',
            })},_vm._l((_vm.popList),function(pItem,pIndex){return _c('div',{key:pIndex,staticClass:"btn_pop_item flex flex-jc flex-ac",on:{"click":function($event){$event.stopPropagation();return _vm.jumpRouter(pItem.router)}}},[_vm._v(" "+_vm._s(pItem.title)+" ")])}),0)])]:[_c('div',{staticClass:"right_wallet flex flex-jc flex-ac",on:{"click":function($event){$event.stopPropagation();return _vm.jumpHistory.apply(null, arguments)}}},[_c('img',{staticClass:"right_wallet_icon",attrs:{"src":require("@/assets/wallet.svg")}}),_c('span',{staticClass:"right_wallet_txt"},[_vm._v(_vm._s(parseInt(Number(_vm.SPK) * 100) / 100)+" TAICE")])]),[_c('img',{staticClass:"right_avatar",attrs:{"src":_vm.avatar || require('@/assets/default-avatar.png')},on:{"click":function($event){$event.stopPropagation();return _vm.btnHandler.apply(null, arguments)},"mouseenter":function($event){return _vm.btnHandler(1)}}}),_c('div',{staticClass:"btn_pop flex flex-col",style:({
              height: `${_vm.showPop ? 24 : 0}rem`,
              border: `${
                _vm.showPop ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'
              }`,
              pointEvents: _vm.showPop ? 'auto' : 'none',
            }),on:{"mouseleave":function($event){return _vm.btnHandler(2)}}},[_c('div',{staticClass:"btn_pop_user"},[_c('div',{staticClass:"btn_pop_user_img"},[_c('img',{attrs:{"src":_vm.avatar || require('@/assets/default-avatar.png'),"alt":""}})]),_c('div',{staticClass:"btn_pop_user_info"},[_c('div',{staticClass:"text1"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"text2"},[_vm._v(_vm._s(_vm._f("chainFilter")(_vm.chain)))])])]),_vm._l((_vm.popList),function(pItem,pIndex){return _c('div',{key:pIndex,staticClass:"btn_pop_item flex flex-ac",on:{"click":function($event){$event.stopPropagation();return _vm.jumpRouter(pItem.router)}}},[_c('img',{staticClass:"btn_pop_item_icon",attrs:{"src":pItem.url,"alt":""}}),_vm._v(" "+_vm._s(pItem.title)+" ")])})],2)]]],2):_c('div',{staticClass:"btn flex flex-jc flex-ac",on:{"click":function($event){$event.stopPropagation();return _vm.btnHandler.apply(null, arguments)}}},[_c('span',{staticClass:"btn_txt"},[_vm._v("Login")])])]),_c('ul',{staticClass:"tagpop flex flex-col flex-jfs flex-ac",style:({ maxHeight: _vm.showFlag && _vm.wdWidth <= 900 ? '70vh' : 0 }),on:{"click":function($event){$event.stopPropagation();}}},_vm._l((_vm.tagList),function(tag,tIndex){return _c('li',{key:tIndex,staticClass:"tagpop_item",style:({
        background: _vm.activeIndex == tIndex ? '#FE5900' : 'transparent',
      }),on:{"click":function($event){$event.stopPropagation();return _vm.changeTag(tIndex)}}},[_vm._v(" "+_vm._s(tag)+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }